<template>
  <AuthCard :title="$t('auth.forgotPasswordView.title')" @submit="onSubmit">
    <v-card-text>
      {{ $t("auth.forgotPasswordView.description") }}
    </v-card-text>
    <v-card-text class="pb-0">
      <VxTextField
        v-model="form.email"
        :label="$t('auth.forgotPasswordView.emailField.label')"
        name="email"
        type="email"
        append-icon="$email"
        autocomplete="off"
        :placeholder="$t('auth.forgotPasswordView.emailField.placeholder')"
        rules="required|email"
      />
    </v-card-text>
    <template #links>
      <v-spacer />
      <VxBtn class="px-0" text small @click="onLogin">
        {{ $t("auth.forgotPasswordView.goToLoginText") }}
      </VxBtn>
    </template>
  </AuthCard>
</template>

<script>
import axios from "axios";
const $http = axios.create({
  baseURL: ``,
});

import AuthCard from "./components/AuthCard.vue";
import { VxTextField, VxBtn } from "@/core-ui";

export default {
  name: "ForgotPasswordView",
  components: { AuthCard, VxTextField, VxBtn },
  data: () => ({
    form: { email: null },
  }),
  methods: {
    async onSubmit({ values, setErrors }) {
      try {
        await $http.post("api/users/password", {
          user: { email: values.email },
        });

        this.$router.push({
          name: "auth.login",
          params: {
            alert: this.$t("auth.forgotPasswordView.resetPasswordAlert"),
          },
        });
      } catch (e) {
        setErrors([this.$t("auth.forgotPasswordView.failedActionText")]);
      }
    },
    onLogin() {
      this.$router.push({ name: "auth.login" });
    },
  },
};
</script>
